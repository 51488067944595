import React from "react";

function Navbar({ switchToApp }) {
  return (
    <nav className="py-5 mt-5">
      {/* <button
        type="submit"
        className="w-[90%] md:w-[30%] py-3 px-6 bg-green-300 hover:opacity-70 rounded-full mx-auto shadow-xl shadow-light-blue"
        style={{ float: "right" }}
        onClick={switchToApp}
      >
        Try the demo!
      </button> */}
    </nav>
  );
}

export default Navbar;
