import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import TutorConversation from './TutorConversation';
import Landing from "./landing/Landing";

const rootDiv = document.getElementById('root');
if (!rootDiv) {
  throw Error("The react app has been misconfigured and no root div was found.");
}
const root = ReactDOM.createRoot(rootDiv);

function Root() {
  const [isLanding, setIsLanding] = useState<boolean>(true);
  return (
    <React.StrictMode>
      { isLanding ? <Landing switchToApp={() => setIsLanding(false)} /> : <TutorConversation /> }
    </React.StrictMode>
  );
}
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
