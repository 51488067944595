import React, { JSX } from "react";
import Form from "./Form";

import image from "../assets/learner.svg";

function Header() {
  return (
    <header className="text-center py-5 md:py-14">
      <h1 className="text-xl md:text-4xl">
        We solve the {asBold("WHAT")} and {asBold("HOW")} to study using Gen AI.
      </h1>
      <p className="text-sm md:text-lg py-5 text-gray-500">
        Subscribe and get notified
      </p>

      <Form />
      
      <img
        src={image}
        alt="the dashboard image"
        className="w-[70%] md:w-[60%] mx-auto mt-10"
      />
    </header>
  );
}

function asBold(text: string): JSX.Element {
  return <span className="font-extrabold">{text}</span>;
}

export default Header;
