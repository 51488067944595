import React, { useState, useEffect } from 'react';
import ChatBox from "./chat/ChatBox";
import Modal from "./Modal";
import config from "./config"
const SERVER = config.server[process.env.NODE_ENV];

import logo from './logo.svg';
import attachIcon from "./attachIcon.svg";
import tutorAvatar from "./tutorAvatar.jpg";
import userAvatar from "./userAvatar.jpeg";

type Message = {
  author?: {
    username: string;
    id: number;
    avatarUrl: string | null;
  };
  text: string;
  type: string;
}
type CurrentQuestion = {
  question?: string;
  summary?: string;
  answer?: string;
}

const ADD_NOTES_BANNER = "Use the top-right button to input the notes you want to be assessed on.";
const TUTOR_READY_BANNER = "The tutor will begin asking questions. It will aim to tailor the questions to your level of progress.";

function TutorConversation() {
  const [banner, setBanner] = useState<string>(TUTOR_READY_BANNER);
  const [messages, setMessages] = useState<Message[]>([]);
  const [{ question, summary, answer }, setQuestion] = useState<CurrentQuestion>({
    question: null,
    summary: null,
    answer: null,
  });
  // XXX: Hack for demo, make sure to remove in the future
  const [qAsked, setQAsked] = useState<number>(0);
  const [hideQuestion, setHideQuestion] = useState<boolean>(false);
  const [hasNotes, setHasNotes] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");

  const suffixTutorMessage = answer !== null ? "..." : (question ?? "...");

  // TODO: Effect runs twice on first paint, bandaid fix but we should fix this
  // to reduce the cost of running askQuestion twice.
  useEffect(() => {
    (async ()  => {
      if (qAsked >= 3) {
        setQuestion({
          question: "Draw the basic structure of a plant cell with arrows pointing to the different organelles.",
          summary: null,
          answer: null,
        });
        return;
      }
      if (!hasNotes || answer || question) {
        return;
      }
      const response = await askQuestion(); 
      if (!response) {
        setHasNotes(false);
        return;
      }
      setQuestion((state) => ({
        question: state.question ?? response.question,
        summary: state.summary ?? response.summary,
        answer: null,
      }));
    })();
  }, [answer, hasNotes]);
  useEffect(() => {
    (async () => {
      if (!answer) {
        return;
      }
      if (qAsked >= 3) {
        setMessages(messages.concat(
          toMessage("Thank you for the session, you're doing great!"),
          toMessage("I will email you updated notes and a summary of the session."),
        ));
        setHideQuestion(true);
        return;
      }
      const answerResponse = await safeFetch(
        `${SERVER}/questions`,
        {
          method: "POST",
          body: JSON.stringify({
            answer,
            question,
            summary,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!answerResponse.ok) {
        const text = await answerResponse.text();
        console.warn(text);
        alert(text);
        return
      }
      const { grade, explanation } = await answerResponse.json();
      setMessages(messages.concat(
        toMessage(`You've answered with an accuracy of ${Number(grade) * 10}%.${explanation}`)
      ));
      setQuestion({ question: null, summary: null, answer: null });
      setQAsked(qAsked => qAsked + 1);
      })();
  }, [answer])
  useEffect(() => setBanner(hasNotes ? TUTOR_READY_BANNER : ADD_NOTES_BANNER), [hasNotes]);
  
  const handleOnSendMessage = async (answer: string) => {
    setMessages(messages.concat(
      toMessage(question),
      toMessage(answer, "Dan"),
    ));
    setQuestion({ question, summary, answer });
  };
  
  const showModal = () => {
    setIsUploading(true);
  };

  const hideModal = () => {
    setIsUploading(false);
  };

  const updateNotes = (e: any) => {
    setNotes(e.target.value);
  }

  const uploadNotes = async () => {
    const response = await safeFetch(
      `${SERVER}/notes`,
      {
        method: "POST",
        body: JSON.stringify({ notes: encodeURIComponent(notes) }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    hideModal();
    setNotes("");
    if (!response.ok) {
      alert(await response.text());
      return;
    }
    setHasNotes(true);
  }

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "5vh",
          borderBottom: "1px solid black",
        }}
      >
        <img
          src={attachIcon}
          style={{
            height: "80%",
            padding: "0.3%",
            marginTop: "0.35%",
            marginRight: "1%",
            float: "right",
            border: "1px dotted black",
          }}
          onClick={showModal}
        />
      </div>
      {/* TODO: Add microphone button */}
      {/* TODO: Align avatar with text instead of name */}
      <ChatBox
        messages={
          hideQuestion ?
          [toBanner(banner), ...messages] : 
          [toBanner(banner), ...messages, toMessage(suffixTutorMessage)]
        }
        disabledInput={question === null}
        userId={1}
        onSendMessage={handleOnSendMessage}
        width="100vw"
        height="95vh"
      />
      <Modal show={isUploading} handleClose={hideModal}>
        <textarea
          value={notes}
          onChange={updateNotes}
          className="border-2 border-gray-500"
          style={{ height: "220px", width: "450px", marginLeft: "25px", marginTop: "10px" }}
        />
        <div className="grid grid-flow-col justify-items-center">
          <button className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-1 px-4 rounded">Upload a PDF</button>
          <button className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-1 px-4 rounded">Upload an image</button>
          <button className=" bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-1 px-4 rounded">Submit URL</button>
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-1 px-4 rounded"
            onClick={uploadNotes}
          > Submit text</button>
        </div>
      </Modal>
    </>
  );
}

async function askQuestion() {
  const response = await safeFetch(`${SERVER}/questions`);
  if (!response.ok) {
    if (response.status === 400) {
      return undefined;
    }
    console.error(`Unexpected errror: ${await response.text()}`);
    alert("Something went wrong, please reload the page or contact support.");
    return undefined;
  }
  return response.json();
}

function toBanner(banner: string): Message {
  return {
    text: banner,
    type: 'notification',
  };
}

function toMessage(question: string, from: 'Tutor' | 'Dan' = 'Tutor'): Message {
  return {
    author: {
      username: from,
      id: from === 'Tutor' ? 2 : 1,
      avatarUrl: from === 'Tutor' ? tutorAvatar : userAvatar,
    },
    text: question,
    type: 'text',
  };
}

async function safeFetch(url: string, options: RequestInit = {}): Promise<Response> {
  try {
    return await fetch(url, options);
  } catch (err) {
    console.error(err.message);
    return { ok: false, status: 500, text: () => Promise.resolve(err.message) } as Response;
  }
}

export default TutorConversation;